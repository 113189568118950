<template>
  <section class="card">

    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header">
        <b>Relatório de Requisições</b>
      </template>
      <template slot="subheader">
        <br />
        <small>Para extrair o relatório, clique em filtrar ao lado</small>
      </template>
    </data-header>
    <div class="row main-row">
      <filtro-component :filtros="filtros"
                        :params="params"
                        :handleConsultar="handleConsultar">
      </filtro-component>
      <data-table :result="result"
                  :params="params"
                  :handleRowClick="handleRowClick"
                  :checkboxClass="resolveCheckboxClass"
                  :itemSelecionado="itemSelecionado[0]"
                  :resolveStatusClass="resolveStatusClass"
                  :handleOrdenar="handleOrdenar"
                  :selected="selected"
                  :filtros="filtros"
                  :handleConsultar="handleConsultar">
        <template slot="tfoot" v-if="result && result.Records && result.Records.length">
          <tr>
            <td colspan="5"><b class="pull-right">{{result.Records.length}} resultados</b></td>
            <td colspan="5" class="total">Soma: <b>{{(result.Records.length ? result.Records.map(c => c.Valor).reduce((partial_sum, a) => partial_sum + a) : 0).toFixed(2).replace('.',',')}}</b></td>
          </tr>
        </template>
      </data-table>
    </div>
  </section>
</template>

<script>
  import api from '@/services/api.js';
  import consultar from '@/services/consultar.js';
  import { resolveStatusRequisicaoClass } from '@/services/helpers.js';


  //Relatorios Requisicao
  export default {
    components: {
      ...consultar.components,
    },
    data() {
      const that = this;
      return {
        ...consultar.data(),
        url: '/relatorios/requisicoes',
        clone: '',
        obj: {},
        tipoRequisicaoFiltro: [],
        selecionado: 'Relatório de requisições',
        actionButtons: [
          {
            text: '',
            className: 'print padrao',
            icon: 'fas fa-print',
            //iconColor: 'green',
            color: 'default',
            visible: true,
            title: 'Imprimir relatório',
            callback: that.imprimir
          },
          {
            text: '',
            className: 'excel padrao',
            icon: 'fas fa-file-excel',
            iconColor: 'green',
            color: 'default',
            visible: true,
            title: 'Exportar para excel',
            callback: that.exportar

          },
        ],
        itemSelecionado: [0],
        filtros:
        {
          data: [
            {
              id: 'placa',
              title: 'Placa',
              active: false,
              placeholder: 'AAA-1111',
              value: ''
            },
            {
              id: 'requisicao',
              title: 'Requisição',
              active: false,
              type: 'number'
            },
            {
              id: 'StatusVeiculo',
              title: 'Status Veículo',
              active: false,
              value: [],
              source: [
                //{
                //    id: 'StatusVeiculo',
                //    title: 'Pendente',
                //    value: 0
                //},
                {
                  id: 'StatusVeiculo',
                  title: 'Preparo',
                  value: 1
                },
                {
                  id: 'StatusVeiculo',
                  title: 'Estoque',
                  value: 2
                },
                {
                  id: 'StatusVeiculo',
                  title: 'Vendido',
                  value: 3
                },
              ]
            },
            {
              id: 'StatusRequisicao',
              title: 'Status Requisição',
              active: false,
              source: [{
                id: 'StatusRequisicao',
                title: 'Orçamento',
                value: 0
              },
              {
                id: 'StatusRequisicao',
                title: 'Pendente',
                value: 1
              },
              {
                id: 'StatusRequisicao',
                title: 'Reprovado',
                value: 2
              },
              {
                id: 'StatusRequisicao',
                title: 'Aprovado',
                value: 3
              },
              {
                id: 'StatusRequisicao',
                title: 'Enviado',
                value: 4
              },
              {
                id: 'StatusRequisicao',
                title: 'Executado',
                value: 5
              },
              {
                id: 'StatusRequisicao',
                title: 'Encerrado',
                value: 6
              }
              ],
              value: []

            },
            {
              id: 'TipoServico',
              title: 'Tipo Serviço',
              active: false,
              value: [],
              source: []
            },
            {
              id: 'DataCadastro',
              title: 'Data Cadastro',
              active: false,
              type: 'date'
            },
            {
              id: 'DataSituacao',
              title: 'Data Situação',
              active: false,
              type: 'date'
            },
          ],
          visao: [
            {
              id: 0,
              title: 'Todas requisições',
              filtro: [
                {
                  id: 'DataCadastro',
                  valuede: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
                  valueate: ''
                }
              ]
            },
          ],
          visaoSelecionada: 0,
          visible: true
        },

      };
    },
    methods: {
      ...consultar.methods,
      changeCheckboxCallback: function () { this.itemSelecionado = [0] },
      callback: function () { this.itemSelecionado = [0] },
      resolveStatusClass: resolveStatusRequisicaoClass
    },
    mounted: function () {
      this.handleTrocarVisao(true);
    },
    beforeMount: function () {
      let that = this;
      let filtro = this.filtros.data.filter(f => f.id === 'TipoServico')[0];
      api.get(this.url + '?preload=true')
        .then(function (response) {
          filtro.source = response.data.tipoRequisicaoFiltro;
        });
    }
  };
</script>


<style scoped>


  td b {
    display: inline-block;
    text-decoration: underline
  }

  .total {
    text-align: right
  }
</style>
